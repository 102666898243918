.referrer-form-links {
    &__title {
        margin-bottom: 18px;
        font-size: 20px;
        font-weight: 500;
    }

    &__allJobsButton {
        margin-top: 30px;
    }
}