.referrer-stats-trigger {
    position: fixed;
    bottom: -75px;
    inset-inline-start: 20px;
    background-color: $primary-color;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    font-size: 25px;
    box-shadow: 1px 1px 5px 0px #8f8d8d;
    transition: all .3s;
    &.show {
        bottom: 20px;
    }

    &__desc {
        position: absolute;
        color: black;
        font-size: 14px;
        inset-inline-start: 55px;
        width: max-content;
        top: -20px;
        background: white;
        padding: 10px;
        border-radius: 20px;
        border-end-start-radius: 0;
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
        &.show {
            opacity: 1;
        }
    }
}

.referrer-stats-modal {
    max-width: 800px;
    width: 95%;

    .confirmation-modal__title {
        text-align: start;
    }
    .referrer-stats-logout {
        position: absolute;
        inset-inline-start: 30px;
        bottom: 20px;
        min-width: unset;
    }
}

.referrer-stats-title {
    margin-bottom: 10px;
}

.referrer-stats-content {
    padding: 0px 20px;
    @include mobileOnly {
        padding: 0;
    }
}
.referrer-stats-subtitle {
    margin-bottom: 20px;
    line-height: 20px;
    @include mobileOnly {
        font-size: 16px;
    }
}

.referrer-stats-list {
    margin-top: 20px;
    @include mobileOnly {
       justify-content: space-between;
    }
}

.referrer-stats-img {
    width: 250px; height: 250px;
    object-fit: contain;
    @include mobileOnly {
        display: none;
    }
}

.referrer-stats-item {
    gap: 10px;
    text-align: center;
    .icon {
        color: $primary-color;
        font-size: 25px;
        @include mobileOnly {
            font-size: 22px;
        }
    }
    .stat-title {
        font-size: 18px;
        @include mobileOnly {
            font-size: 16px;
        }
    }
    .stat-value {
        font-size: 30px;
        font-weight: bold;
        @include mobileOnly {
            font-size: 25px;
        }
    }
}

.stats-item-separator {
    font-size: 30px;
    & i {
        color: $primary-color;
    }
}