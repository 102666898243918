@mixin size($height, $width) {
    height: $height;
    width: $width;
}

@mixin square($size) {
    @include size($size, $size);
}

@mixin absoluteFill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin publicWidth {
    width: calc(100% - 2 * #{$mainSpacing});
    max-width: $maxPublicSize;
}

@mixin gradientBG {
    background: linear-gradient(90deg, $gradientStart 0%, $gradientEnd 100%);
}

@mixin mobileOnly {
    @media (max-width: 768px) {
        @content;
    }
}
@mixin webOnly {
    @media (min-width: 769px) {
        @content;
    }
}

@mixin ieOnly {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin flex($flex:1) {
    flex: $flex;
    @include ieOnly {
        flex: $flex 1 auto;
    }
}

@mixin rtl {
    [dir=rtl] & {
        @content;
    }
}
