.jobs-list {
    position: relative;
    min-height: 100px;
    
    &__wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0;
        margin-top: 0;
    }

    &__item {
        height: 336px;
        margin: 10px 0;
        display: inline-flex;
        justify-content: center;
        overflow: hidden;
        
        & > a {width: 100%;}

        @include mobileOnly {
            height: auto;
        }
    }
}