.stat-top-table {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    @include flex();

    &__header {
        color: white;
        padding: 28px 30px 40px;
        background: #d7d7d8 url(/images/skyline.png) center/cover no-repeat;
        background-size: cover;
    }
    
    &__title {
        font-size: 25px;
    }
    &__counter {
        font-size: 72px;
        font-weight: bold;
        margin: 20px 10px;
    }

    .ReactTable {
        margin-top: -50px;
        position: relative;
        .rt-thead {
            background-color: #ffffff1a;;
            color: white;
        }
    }
}