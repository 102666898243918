.drag-list {
    gap: 5px;
    display: flex;
    flex-direction: column;
    &__item {
        padding: 8px;
        border-style: 'solid';
        border-width: 4px;
        border-radius: 4px;
        border-color: 'rgba(0, 0, 0, 0.17)';
        display: flex;
        gap: 10px;
        position: relative;

        .handle-wrapper {
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: -1;
            }
        }
        &:active {
            .handle-wrapper:after {pointer-events: none;}
        }
        .handle-btn {
            cursor: move;
        }
        .item-actions {
            z-index: 100;
        }
    }
}