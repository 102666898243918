.login-page {
    display: flex;
    @include square(100%);
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &__bg {
        position: fixed;
        top: 0;
        right: 0;
        @include square(100%);
        background: url(/images/login-bg.png) center/cover no-repeat;
    }

    .loginBox {
        background: white;
        box-shadow: 0 5px 25px 0 rgba(0, 27, 45, 0.65);
        position: relative;
        max-width: 350px;
        @include ieOnly {
            display: none;
        }
        .input-wrapper {
            margin-bottom: 10px;
        }
        &__header {
            background: $blue;
            color: white;
            padding: 20px 10px;
            text-align: center;
            font-size: 20px;
            align-self: stretch;
            margin-bottom: 10px;
        }
        &__content {
            margin: 20px 40px 30px;
            align-items: stretch;
        }
        &__error {
            background: rgb(255, 53, 53);
            color: white;
            align-self: stretch;
            text-align: center;
            padding: 5px;
        }
        &__logo {
            width: auto;
            height: 30px;
            align-self: center;
            margin-top: 5px;
        }

        .qrCode {
            align-self: center;
            max-width: 100%;
        }

        .mfaTop {
            margin-bottom: 10px;
            line-height: 20px;
        }
    }

    &__joinUs {
        margin-top: 30px;
        position: relative;
        font-size: 22px;
        color: white;
        text-align: center;
        white-space: pre-line;
        line-height: 30px;
        &--link {
            color: $blue;
            text-decoration: underline;
            margin-right: 3px;
        }
    }
    & .not-supported {
        display: none;
        @include ieOnly {
            display: block;
            text-align: center;
            font-size: 25px;
            text-align: center;
            z-index: 10000;
            span {
                padding: 20px;
                margin: 20px;
                background: white;
                border: red 4px solid;
                display: inline-block;
            }
        }
    }

    .alternative-action {
        margin-top: 20px;
        text-align: center;
        color: $primary-color;
        &:hover {
            text-decoration: underline;
        }
    }
}