.job-route {
    margin: 10px 0;
    .job-info {
        margin: 0;
    }
    &__separator {
        background-color:  $separator;
        height: 1px;
        margin: 30px 0;
    }
    &__allJobsButton {
        margin: 50px 0 40px;
        align-self: center;
        .button {
            height: 50px;
            padding: 0 45px;
            font-size: 20px;
        }
    }
}