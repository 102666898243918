.login-providers-page {
    display: flex;
    @include square(100%);
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &__bg {
        position: fixed;
        top: 0;
        right: 0;
        @include square(100%);
        background: url(/images/login-bg.png) center/cover no-repeat;
    }

    .loginBox {
        background: white;
        box-shadow: 0 5px 25px 0 rgba(0, 27, 45, 0.65);
        position: relative;
        max-width: 350px;
        width: 95%;

        @include ieOnly {
            display: none;
        }
        .input-wrapper {
            margin-bottom: 10px;
        }
        &__header {
            background: $blue;
            color: white;
            padding: 20px 10px;
            text-align: center;
            font-size: 20px;
            align-self: stretch;
            margin-bottom: 10px;
        }
        &__content {
            margin: 20px 40px 30px;
            align-items: stretch;
        }
        &__error {
            background: rgb(255, 53, 53);
            color: white;
            align-self: stretch;
            text-align: center;
            padding: 5px;
        }
        &__logo {
            width: auto;
            height: 30px;
            align-self: center;
            margin-top: 5px;
        }
    }

    &__joinUs {
        margin-top: 30px;
        position: relative;
        font-size: 22px;
        color: white;
        text-align: center;
        white-space: pre-line;
        line-height: 30px;
        &--link {
            color: $blue;
            text-decoration: underline;
            margin-right: 3px;
        }
    }
    & .not-supported {
        display: none;
        @include ieOnly {
            display: block;
            text-align: center;
            font-size: 25px;
            text-align: center;
            z-index: 10000;
            span {
                padding: 20px;
                margin: 20px;
                background: white;
                border: red 4px solid;
                display: inline-block;
            }
        }
    }

    .login-header-actions {
        font-size: 15px;
        a {text-decoration: underline;}
    }
    .loginForm {display: flex; flex-direction: column; align-items: stretch;}

    .login-providers-list {margin-top: 20px; gap: 10px;}
    .login-provider-btn {width: 100%;}
    .providers-title {font-size: 18px; margin-top: 20px;}
}