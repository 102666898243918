.referrer-modal {
    position: relative;
    max-width: 660px;
    
    &__column:not(:last-of-type) {
        margin-right: 20px;
        @include rtl {
            margin-right: 0;
            margin-left: 20px;
        }
    }
    .error-msg {
        margin-bottom: 20px;
    }
}