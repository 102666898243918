.job-item {
    @include square(100%);
    overflow: hidden;
    
    &__main {
        max-height: 100%;
        @include ieOnly {
            flex: 1;
        }
    }
    &__title {
        margin-bottom: 10px;
    }

    &__infoButton {
        align-self: flex-end;
        margin-right: 20px;
        margin-top: 22px;
    }
    
    &__description {
        text-align: inherit;
        white-space: pre-line;
        line-height: 30px;
        @include flex();
        overflow: hidden;
        text-overflow: ellipsis;
        @include mobileOnly {
            max-height: 173px;
            font-size: 16px;
            line-height: 25px;
            flex: 0;
        }
    }
    .job-highlight {
        margin-bottom: 10px;
    }
}