.integrations-page {
    position: relative;
    &__submit {
        margin: 20px 0 10px;
        align-self: flex-end;
    }

    .integration-title {
        font-weight: 500;
        font-size: 20px;
    }

    .int-conf-fields {margin-top: 15px;}
}