.company-terms {
    margin-top: 30px;
    margin-bottom: 30px;
    &__column {
        @include publicWidth();
        @include size(auto, 100%);
        @include mobileOnly {
            @include size(auto, 100%);
            margin-top: 20px;
        }
        &:not(:last-of-type) {
            margin-right: 20px;
            @include rtl {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }
    @include mobileOnly {
        height: auto;
        flex-direction: column;
        align-items: center;
    }
}
.content {
    margin: 20px 0;
    justify-content: space-between;
    align-items: flex-end;
    width:100%;
}
video{
    width: 100%;
    height: auto;
    pointer-events: none; /* Disable any user interaction at all */
}
.title {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 73px;

    color: #000000;
}

.allJobsButton {
align-items: right;

background: #55B1CD;

font-family: 'Heebo';
font-style: normal;
font-weight: 400;
font-size: 20px;

text-align: center;

color: #FFFFFF;


}