.click-to-copy {
    &__trigger {
        align-self: center;
        text-decoration: underline;
        color: $primary-color;
        cursor: pointer;
        margin-left: 10px;
        @include rtl {
            margin-left: 0;
            margin-right: 10px;
        }
    }
}