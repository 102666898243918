.share-stats-icons {
    .icon {
        @include square(22px);
        background: $blue;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-left: 2px;
        font-size: 13px;
        margin-bottom: 4px;
    }
    &__wrapper {
        margin: 0 3px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
    &__separator {
        @include size(30px, 1px);
        background: $borderGray;
        margin: auto 7px;
    }
    &__total {
        font-weight: bold;
    }
}