.company-info {
    width: 100%;
    top: 10px;
    margin: 0 auto;
    font-size: 18px;

    @include mobileOnly {
        font-size: 16px;
    }
    
    &__logo {
        width: auto;
        height: 40px;
        margin-right: 5px;
    }
    &__name {
        font-weight: 500;
        font-size: 20px;
    }
    &__site {
        margin-top: 15px;
        color: $blue;
        text-decoration: underline;
    }
    &__about {
        margin-top: 20px;
        line-height: 1.4;
        white-space: pre-line;
    }
}