.confirmation-modal {
    max-width: 600px;
    &__logoBox {
        padding: 20px;
        text-align: center;
    }
    &__logo {
        width: auto;
        height: 30px;
        align-self: center;
        margin-top: 5px;
    }
    &__title {
        background: $blue;
        color: white;
        padding: 20px 25px;
        text-align: center;
    }

    &__footer {
        padding: 20px 25px;
        .button {
            margin-left: 10px;
        }
    }

    &__content {
        padding: 25px;
        max-height: 75vh;
        overflow: auto;
        .title--regular {
            margin-top: 10px;
            font-size: 18px;
        }
    }

    &--danger {
        .confirmation-modal__title {
            background: $dangerColor;
        }
    }
    .ReactModal__close {
        color: white;
    }
}