$darkGray: #343432;
$darkerGray: #222222;
$deepGray: #a6a6a6;
$lightGray: #f2f2f2;
$disabled: #eaeaea;
$borderGray: #d4d4d4;

$blue: #22b3d0;
$green: #33aa58;
$pink: #ff4294;
$background: #eff1f1;

$gradientStart: #127cd6;
$gradientEnd: #06a0bc;
$separator: #d9dadb;

$primary-color: $blue; // AppLoader
$menuColor: $darkGray;
$textColor: $darkGray;
$errorColor: red;
$dangerColor: red;
$errorBG: #ff7a72;
$warnBG: #f6c467;
