.admin-dashboard {
    margin: 20px;
    .stat-top-table {
        overflow: hidden;
        min-width: 300px;
        @media only screen and (min-width: 700px) {
            min-width: 400px;
        }
    }
    .stat-top-table, .stat-counter {
        margin: 10px;
        width: 100%;
    }
    &__rtl-column {
        text-align: left;
        text-align: start;
        @include rtl {
            text-align: right;
        }
        margin: 0 20px;
    }
    .ReactTable .column-header--start {
        padding-right: 28px;
        padding-left: 28px;
    }
}