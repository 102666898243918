.admin-referrers {
    .referrers-list {
        margin-top: 7px;
    }
    &__export {
        margin-left: 10px;
        @include rtl {
            margin-left: 0;
            margin-right: 10px;
        }
    }
}