.input-wrapper.date-picker {
    &.small {
        .DateInput {
            width: 108px;
        }
    }
    &.field {
        .DateInput {
            &_input {
                height: 42px;
            }
        }
    }
    .SingleDatePicker {
        width: 100%;
        .SingleDatePickerInput {
            width: 100%;
            .DateInput {
                width: 100%;
            }
        }

        .CalendarDay {
            vertical-align: middle;
        }
    }
        
    .DateInput {
        &_input {
            height: 36px;
            font-size: 16px;
            width: 100%;
            border: none;
            background: none;
            text-align: center;
        }
    }
    .DateRangePicker {
        &_picker {
            z-index: 1005;
        }
        .DateInput {
            width: 150px;
        }
        .DateRangePickerInput_clearDates {
            margin-right: 0;
        }
        .DateRangePickerInput__showClearDates {
            padding-right: 18px;
        }
    }
    .time-wrapper {text-align: initial; gap: 10px; padding: 0 20px;}
    .button.button--primary {min-width: 100px;}
    .picker-trigger {
        padding: 0 10px; gap: 10px;
        width: 100%; height: 42px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        .arrow {width: 25px;}
        .picker-label {flex: 1; text-align: center; direction: ltr;}
    }
    &.disabled {
        .picker-trigger {background: $disabled;}
    }
}

.CalendarDay__selected {
    background: $blue;
    border-color: $blue;
    &_span {
        background: rgba($blue, 0.6);
        border-color: rgba($blue, 0.3);
    }
}
.CalendarDay__default {
    vertical-align: middle;
}