.loader {
    display: flex;
    width: 100%;
    height: 100%;
    @include flex();
    align-self: stretch;
    justify-content: center;
    align-items: center;
    z-index: 1500;

    &--inline, &--fixed{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        background: rgba(white, 0.4);
    }

    &--fixed {
        position: fixed;
    }
    &__animation {
        transform: scale(2)
    }
}