.application-form {
    align-items: center;

    &__toggle {
        margin: 20px 0 15px;
        font-size: 14px;
        position: relative;
        padding-left: 20px;
        cursor: pointer;

        &:before, &:after {
            content: "";
            display: block;
            width: 10px;
            height: 2px;
            background: $textColor;
            position: absolute;
            top: 5px;
            transition: transform .5s;
          }
          
          &:before {
            left: 0px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            transform: rotate(45deg);
          }
          
          &:after {
            left: 6px;
            transform: rotate(-45deg);
          }
          
          &.active:before {
            transform: rotate(-45deg);
          }
          
          &.active:after {
            transform: rotate(45deg);
          }
    }

    &__form {
        align-self: center;
    }
    &__submit {
        align-self: center;
    }
    &__check {
        color: $blue;
        font-size: 44px;
        margin-bottom: 20px;
    }
    &__moreJobsWrapper {
        border-top:  $separator solid 1px;
        margin-top: 50px;
        padding-top: 20px;
        align-self: stretch;
        justify-content: space-between;
        align-items: center;
    }
    &__moreJobs-subtitle {
        margin-top: 10px;
        font-size: 18px;
    }
    &__goodLuck {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 400;
    }
    &__show-button {
      margin-right: 20px;
    }
    &__stress{
      font-weight: bold !important;
    }
    &__pos-title {
      font-size: 18px;
    }
}
