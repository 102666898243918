@import 'styles/_base.scss';
@import 'components/_components.scss';
@import 'containers/_containers.scss';
@import 'styles/routes-scss/_routes.scss';

html body, #root {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

html, .top-wrapper {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  font-size: 16px;
  @include mobileOnly {
    font-size: 20px;
  }
}

#root {
  display: flex;
  @include flex();
  align-self: stretch;
  flex-direction: column;
}