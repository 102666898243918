.PlacesAutocomplete {
    &__root {
        width: 100%;
        position: relative;
    }
    &__container {
        position: absolute;
        top: 100%;
        background-color: white;
        border: 1px solid rgb(85, 85, 85);
        width: 100%;
        z-index: 10;
    }
    &__item {
        padding: 10px;
        cursor: pointer;

        i {
            margin-left: 10px;
            color: $blue;
        }

        &:hover {
            background: $blue;
            color: white;
            i {
                color: white;
            }
            cursor: pointer;
        }
    }
}