.vlist {
    &__add-btn {
        margin-top: 22px;
    }
    
    .drag-list {
        max-height: 400px;
        overflow: auto;
        &__item {
            border: 1px solid $lightGray;
            padding: 20px;
            min-width: 250px;
        }
    }

    .btn-del {
        color: $errorColor;
    }
}