.applicant-ai-modal {
    position: relative;
    max-width: 1250px;
    .long {
        @include flex();
        min-height: 150px;
    }

    .input-wrapper.checkbox {
        margin-bottom: 20px;
    }
    &__column {
        &:not(:last-of-type) {
            margin-right: 20px;
            @include rtl {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }
    &__cvFile {
        text-decoration: underline;
        color: $blue;
        display: flex;
        align-items: center;
        font-size: 18px;
        margin: 0 0px 30px;
        height: auto;
        padding: 0;
        min-width: auto;
        line-height: inherit;
        .fa {
            margin-right: 10px;
            @include rtl {
                margin-right: 0;
                margin-left: 10px;
            }
        }
        &:hover {
            color: rgba($blue, 0.8);
        }
    }
    &__link {
        max-width: $inputWidth;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
            color: $blue;
        }
        .icon {
            margin-right: 7px;
            color: $blue;
            @include rtl {
                margin-right: 0;
                margin-left: 7px;
            }
        }
    }
    &__job {
        font-size: 16px;
        margin-bottom: 20px;
    }
    &__company {
        margin-bottom: 5px;
        font-size: 18px;
    }
    &__header {
        margin: 20px 0;
        font-size: 25px;
        font-weight: bold;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 0px solid $separator;
    }
    &__header-content {
        justify-content: center;
    }
    &__content {
        padding: 0 30px;
    }
}