.users-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include flex();
    position: relative;

    &__active {
        font-size: 20px;
        &.fa-check-circle {
            color: $blue;
        }
        &.fa-times-circle {
            color: $pink;
        }
    }

    .ReactTable {
        .rt-td {
            height: 35px;
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
    .mfa-icon {
        color: $primary-color;
    }
}