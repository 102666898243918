.jobs-filter {
    align-items: center;
    .input-wrapper {
        margin-left: 10px;
        &.small {
            flex: 1;
            max-width: unset;
            @include mobileOnly {
                min-width: 100%;
            }
        }
    }
}