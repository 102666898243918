.cmp-admin-settings {
    position: relative;
    @include flex();

    &__submit {
        margin: 20px 0 30px;
        align-self: flex-end;
        
        .warning {
            color: $errorColor;
            margin: 0 10px;
            font-weight: bold;
        }
    }

    .input-wrapper {
        margin-bottom: 15px;
    }
}