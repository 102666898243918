.ReactModal {
    &Portal &__Overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 2000;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 1s;
        &--after-open {
            opacity: 1;
        }
        &--before-close {
            opacity: 0;
        }
        &--hidden {
            left: 50%;
            transform: translateX(-50%);
            right: unset;
            bottom: unset;
        }
    }

    &__Content {
        background: rgb(255, 255, 255);
        overflow: auto;
        outline: none;
        position: relative;
        padding: 40px 30px;
        &.ReactModal--noPadding {
            padding: 0;
        }
    }

    &__close {
        position: absolute;
        inset-inline-end: 15px;
        top: 19px;
        font-size: 20px;
        color: $blue;
        cursor: pointer;
    }

    &Portal > div {
        opacity: 0;
    }
}
