.input-wrapper.editor {
    width: 100%;
    .demo-editor.rdw-editor-main {
        padding: 5px;
        max-height: 343px;
        min-height: 200px;
    }
    .rdw-dropdown-carettoclose, .rdw-dropdown-carettoopen {
        left: 10%;
        right: unset;
    }
    .DraftEditor-root {
        font-size: 20px;
        h3, h3 * {
            color: $blue !important;
            font-weight: 500;
        }
    }
    .DraftEditor-editorContainer {
        z-index: initial;
    }
}
