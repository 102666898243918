.referrers-filter {
    position: relative;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    &__items {
        align-items: center;
        margin-top: 20px;
    }
    .input-wrapper {
        margin-right: 10px;
        @include rtl {
            margin-right: 0;
            margin-left: 10px;
        }
        &.small {
            flex: 1;
            max-width: unset;
            @include mobileOnly {
                min-width: 100%;
            }
        }
    }
}