.stat-counter {
    @include gradientBG();
    @include flex();
    color: white;
    padding: 25px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    &__wrapper {
        @include flex();
        display: flex;
    }
    &__circle {
        @include square(150px);
        border: 1px solid white;
        border-radius: 50%;
        text-align: center;
    }
    &__counter {
        font-size: 48px;
        font-weight: bold;
    }
    &__label {
        font-size: 15px;
        margin-top: 6px;
    }
}