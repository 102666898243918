.checkbox {
    .label {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    input {
        margin: 1px 4px 1px 0;
        @include rtl {
            margin: 1px 0 1px 4px;
        }
    }
}