.form.applicants-filter {
    margin: 5px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1400px) and (min-width: 1150px) {
        justify-content: flex-start;
    }
    .input-wrapper {
        margin-left: 10px;
        &.small {
            min-width: 140px;
            @include mobileOnly {
                min-width: 100%;
            }
        }
    }

    .filter-submit {
        position: relative;
        bottom: 9px;
        align-self: flex-end;
        margin: 0 0 10px 10px;
        @include rtl {
            margin-left: 0;
        }
    }
}