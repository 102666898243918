.container1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px; // Adjust height as needed
    gap: 16px; // Add spacing between boxes
    flex-wrap: wrap;
}

.box1 {
    flex: 1;    
    justify-content: center;
    align-items: center;
    min-width: calc(25% - 16px); // Maintain proportions on resize
}

.box2 {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(75% - 16px); // Maintain proportions on resize
}

.progress {
    display: flex;
    flex-direction: row;
    text-align: center;
    text-overflow: middle;
}

.innerBox {
    background-color: white;
}

.ai_title {
    font-size: 35px;
    font-weight: bold;
    padding: 20px;
    text-align: center;
}

.recommendations_box {
    background-color: #22b3d0;
}

.recommended_item {
    background-color: #eff1f1;
    margin-bottom: 30px;
    padding: 10px;
    font-size: 20px;
}

.recommendations_button {
    background-color: white;
    color: #22b3d0;
    border: 1px solid #06a0bc;
    align-self: flex-end;
}

.save_btn {
    align-self: flex-end;
}

.requirements-desc {
    font-size: 23px;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);

}

.requirements-table {
    border-collapse: inherit;
    font-size: 23px;
    border-spacing: 10px;
    background-color: white;;
}

.requirements-table th {
    border: 1px solid rgb(134, 134, 134);
    font-weight: bold;
    padding: 15px;
    background-color: rgb(234,234,234);
}

.requirements-table td {
    border: 1px solid rgb(134, 134, 134);
    padding: 15px;
    background-color: rgb(234,234,234);
}

.candidate-table {
    border-collapse: inherit;
    font-size: 23px;
    border-spacing: 0 10px;
    background-color: white;;
}

.candidate-table tr {
    border: 1px solid rgb(134, 134, 134);
    background-color: rgb(234,234,234);
}

.candidate-table th {
    font-weight: bold;
    padding: 15px;
}

.candidate-table td {
    padding: 15px;
}

.req-type-control {
    height: 100%;
    width: 80%;
    font-size: 20px;
}

.create-edit-job-ai {
    position: relative;
    @include flex();
    &__map {
        @include flex();
        height: $mapHeight;
        margin-bottom: 20px;
        width: 100%;
    }
    &__submit {
        margin: 20px 0 30px;
        align-self: flex-end;
        
        .warning {
            color: $errorColor;
            margin: 0 10px;
            font-weight: bold;
        }
    }
    &__active-toggle {
        margin: 0 20px;

        @include mobileOnly {
            order: 1;
            margin: 20px;
        }
    }
    &__top-row {
        margin-bottom: 10px;
        align-items: center;

        @include mobileOnly {
            justify-content: center;
            order: -1;
        }
    }
    .input-wrapper {
        margin-bottom: 15px;
    }
    .cover-wrapper {
        flex: 2;
    }

    .applicantMetaImg-wrapper, .referrerMetaImg-wrapper  {
        min-width: unset;
        .file-upload {
            width: 150px;
            height: 150px;
        }
    }

    .applicantMetaImgFB-wrapper, .referrerMetaImgFB-wrapper  {
        .file-upload {
            height: 150px;
        }
    }
    
    &__cpyTrigger {
        margin-bottom: 15px;
        margin-right: 20px;
        @include rtl {
            margin-right: 0;
            margin-left: 20px;
        }
    }

    .bot-links-wrapper {
        margin-top: 21px;
        margin-bottom: 39px;
        margin-inline-end: 18px;
        justify-content: flex-start;
        gap: 20px;
    }
}