.company-logo {
    &__img {
        width: auto;
        height: 55px;
        margin-right: 5px;
        @include mobileOnly {
            height: 40px;
        }
    }
    .title {
        margin-top: 10px;
        font-weight: normal;
    }
}