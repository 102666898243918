.ReactTable {
    width: 100%;

    .rt-th {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
    }

    .rt-tbody .rt-tr:hover {
        background-color: rgba($blue, 0.2) !important
    }
    .rt-tr {
        height: 100%;
        padding: 10px 0;
        text-align: center;
        vertical-align: center;
        opacity: 1;
        transition: opacity .3s;
        &.deleted:not(:hover) {
            opacity: 0.3;
        }
    }
    .rt-td, .rt-th {
        margin: auto;
        &.rtl-column {
            text-align: left;
            @include rtl {
                text-align: right;
            }
        }
    }

    &--clickable {
        .rt-tr {
            cursor: pointer;
        }
    }

    &__deleteButton {
        color: red;
        font-size: 25px;
        cursor: pointer;
        &:hover {
            color: #ff7171;
        }
    }

    .column-header--start {
        justify-content: flex-start;
        padding: 0 25px;
    }
    
    &.rtl {
        .rt-thead .rt-th:last-child, .rt-thead .rt-td:last-child {
            border-right: 1px solid rgba(0,0,0,0.05);
        }
        .rt-thead .rt-th:first-child, .rt-thead .rt-td:first-child {
            border-right: 0;
        }
    }
    a:hover{
        color: $blue;
    }
    .rt-noData {
        display: none;
    }
    .rt-thead.-header {
        box-shadow: none;
        position: relative;
        &:after {
            content:'';
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 2px;
        }
    }
}