.create-edit-job {
    position: relative;
    @include flex();
    &__map {
        @include flex();
        height: $mapHeight;
        margin-bottom: 20px;
        width: 100%;
    }
    &__submit {
        margin: 20px 0 30px;
        align-self: flex-end;
        
        .warning {
            color: $errorColor;
            margin: 0 10px;
            font-weight: bold;
        }
    }
    &__active-toggle {
        margin: 0 20px;

        @include mobileOnly {
            order: 1;
            margin: 20px;
        }
    }
    &__top-row {
        margin-bottom: 10px;
        align-items: center;

        @include mobileOnly {
            justify-content: center;
            order: -1;
        }
    }
    .input-wrapper {
        margin-bottom: 15px;
    }
    .cover-wrapper {
        flex: 2;
    }

    .applicantMetaImg-wrapper, .referrerMetaImg-wrapper  {
        min-width: unset;
        .file-upload {
            width: 150px;
            height: 150px;
        }
    }

    .applicantMetaImgFB-wrapper, .referrerMetaImgFB-wrapper  {
        .file-upload {
            height: 150px;
        }
    }
    
    &__cpyTrigger {
        margin-bottom: 15px;
        margin-right: 20px;
        @include rtl {
            margin-right: 0;
            margin-left: 20px;
        }
    }

    .bot-links-wrapper {
        margin-top: 21px;
        margin-bottom: 39px;
        margin-inline-end: 18px;
        justify-content: flex-start;
        gap: 20px;
    }
}