.icon-button {
    font-size: 25px;
    cursor: pointer;

    color: #424242;
    &:hover {
        color: #a9a9a9;
    }

    &--danger {
        color: red;
        &:hover {
            color: #ff7171;
        }
    }

    &[disabled] {
        opacity: 0.6;
    }
}