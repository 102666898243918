.cmp-settings {
    position: relative;
    @include flex();

    &__submit {
        margin: 50px 0 30px;
        .warning {
            color: $errorColor;
            margin: 0 10px;
            font-weight: bold;
        }
    }
}