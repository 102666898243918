.file-upload {
    @include square(100%);
    border: 1px dashed $textColor;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    min-height: 44px;
    width: $inputWidth;

    &--withValue {
        border-style: solid;
    }
    
    &.error{
        border-color: $errorColor;
        background-color: #ffdddd;
    }

    &.active {
        border-width: 2px;
        background-color: #e6ffca;
        border-color: #1a8000;
        box-shadow: 0 0 10px #5f5f5f;
    }
    &.reject {
        border-color: $errorColor;
        border-style: solid;
        background-color: #ffdddd;
    }

    &__text {
        &:before {
            content: "";
            @include square(20px);
            background-image: url(/images/plus.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 5px;
            display: inline-block;
        }
        display: flex;
        align-items: center;
        &__recommendation {
                font-weight: bold;
                font-size: 12px;
                color: darkgrey;
                font-style: italic;
                padding: 5px;
        }
         &__error {
                color: $errorColor;
        }
        @include mobileOnly {
            font-size: 16px;
        }
    }
    &__preview {
        @include square(100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: contain;
    }

    &__preview-wrapper {
        @include square(100%);
    }

    &__preview-hover {
        display: none;
        @include square(100%);
        background: rgba(white, 0.7);
        position: relative;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
    }

    &__preview-content {
        display: flex;
        margin: 5px;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        @include ieOnly {
            height: 100%;
            margin: 0;
            padding: 5px;
        }
    }
    &__preview-icon {
        color: $blue;
        font-size: 20px;
        margin: 0 5px;
    }
    &__preview-text {
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &__preview-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobileOnly {
            font-size: 16px;
        }
    }
    &__preview-size {
        white-space: nowrap;
        margin: 0 5px;
        font-size: 12px;
        color: $deepGray;
        direction: ltr;
    }

    &__placeholder-wrapper {
        @include flex();
        padding: 5px 10px;
    }
    &:hover {
        .file-upload__preview-hover {
            display: flex;
        }
    }

    &__remove {
        position: absolute;
        top:10px;
        left: 10px;
        color: red;
        font-size: 30px;
        cursor: pointer;
        &:hover {
            color: #ff8282;
        }
    }
}