.admin-page {
    width: 100%;
    height: 100%;
    
    &__wrapper {
        height: 100%;
        @include ieOnly {
            display: none!important;
        }
    }
    &__main {
        height: 100%;        
        width: calc(100% - #{$adminMenuWidth});
        align-self: flex-end;
        position: relative;
        @media only screen and (max-width: 586px) {
            width: 100%;
        }
    }
    &__header {
        height: $adminHeaderHeight;
        background: $lightGray;
        width: 100%;
        position: relative;
        justify-content: center;
        align-items: center;
        padding-right: $adminMenuWidth;
        @media only screen and (max-width: 586px) {
            padding-right: 0;
        }

        .lang-wrapper {
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
            @include rtl {
                right: unset;
                left: 35px;
            }
        }
    }
    &__logo {
        height: 100%;
        margin: auto;
        padding: 20px;
        display: block;
    }
    &__menu-backdrop {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        right:0;
        background: rgba(black, 0.4);
        pointer-events: none;
        transition: all 1s;
        opacity: 0;
    }
    &__menu-toggle {
        font-size: 30px;
        color: $blue;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: 0 15px;
        display: none;
        @media only screen and (max-width: 586px) {
            display: block;
        }
    }
    &__menu {
        height: 100%;
        width: $adminMenuWidth;
        position: fixed;
        z-index: 500;
        @include mobileOnly {
            z-index: 1600;
        }
        @include ieOnly {
            display: none!important;
        }
        @media only screen and (max-width: 586px) {
            right: -($adminMenuWidth + 10px);
            transition: all 0.2s;
            &.active {
                right: 0;
                .admin-page__menu-backdrop {
                    display: block;
                    opacity: 1;
                    pointer-events: initial;
                }
            }
        }
    }

    .admin-applicants-route {
        flex-wrap: nowrap;
    }

    & .not-supported {
        display: none;
        @include ieOnly {
            display: block;
            text-align: center;
            font-size: 25px;
            text-align: center;
            span {
                padding: 20px;
                margin: 20px;
                background: white;
                border: red 4px solid;
                display: inline-block;
            }
        }
    }
}