.image-upload {
    @include square($inputWidth);
    .file-upload {
        @include flex();
        border: 1px solid $borderGray;
    }

    &__preview {
        @include square(100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: contain;
    }
    &--cover {
        width: 100%;
        .image-upload__preview {
            object-fit: cover;
        }
    }
    &--no-dim {
        width: 100%;
        height: 100%;
        min-width: unset;
    }

    &__text {
        display: flex;
        align-items: center;
        color: $deepGray;
        justify-content: center;
        &__recommendation {
                font-weight: bold;
                font-size: 12px;
                font-style: italic;
                color: $deepGray;
                padding: 5px;
        }
         &__error {
                color: $errorColor;
        }
    }

    &__placeholder {
        background: url(/images/upload.png) center no-repeat;
        @include square(40px);
        display: inline-block;
        margin-bottom: 10px;
    }
}