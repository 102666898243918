.c-notif-modal {
    position: relative;
    max-width: 800px;
    width: 95%;

    &__link {
        max-width: $inputWidth;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
            color: $blue;
        }
        .icon {
            margin-right: 7px;
            color: $blue;
            @include rtl {
                margin-right: 0;
                margin-left: 7px;
            }
        }
    }
    &__job {
        font-size: 16px;
        margin-bottom: 20px;
    }
    &__company {
        margin-bottom: 5px;
        font-size: 18px;
    }
    &__header {
        margin: 20px 0;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid $separator;
    }
    &__header-content {
        justify-content: center;
    }
    &__content {
        padding: 0 30px;
    }

    .col-img-wrap {
        flex: 0.5;
        margin-right: 10px;
    }
    .notif-img {
        max-width: 100%;
    }
    .notif-img-label {
        margin-bottom: 5px;
        color: $deepGray;
        font-size: 15px;
    }

    .error-msg {margin-top: 15px;}
}
