.cmp-footer {
    background: $darkGray;
    min-height: $footerHeight;
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    padding: 20px 0;
    align-items: center;

    @include mobileOnly {
        height: auto;
        flex-direction: column;
        align-items: center;
    }
    &__content {
        @include publicWidth();
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        position: relative;
        @include mobileOnly {
            flex-direction: column;
        }
    }
    &__logo {
        height: 28px;
        filter: brightness(10) grayscale(10);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include mobileOnly {
            position: relative;
            transform: none;
            left: unset;
            top: unset;
        }
    }

    &__credit {
        font-size: 12px;
        color: $deepGray;
        &:not(:last-of-type):after {
            content: "|";
            margin: 0 10px;
        }
    }

    .links {
        @include mobileOnly {
            margin-top: 20px;
        }
        a:not(:last-of-type):after {
            content: "|";
            margin: 0 10px;
        }
    }
    .credits {
        @include mobileOnly {
            margin-top: 20px;
        }
    }
}
