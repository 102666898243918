.cmp-header {
    background: white;
    height: $publicHeaderHeight;
    display: flex;
    justify-content: center;

    &__content {
        @include publicWidth();
        display: flex;
        align-items: center;
        height: 100%;

        @include mobileOnly {
            justify-content: center;
        }
    }
    &__logo {
        height: 50%;
        width: auto;
        min-width: 1px;
        @include mobileOnly {
            height: 40%;
        }
    }
}
